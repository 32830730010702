import { onBeforeMount, onUnmounted } from 'vue';
import {
  type NavigationItem,
  navigationItemsOverride,
  navigationBarMode,
  NavigationBarMode,
} from '@mfe/core-navigation';
import { Unsub } from '@mfl/framework';
import userIcon from './user.svg';
import usersIcon from './users.svg';
import planAndBillingIcon from './plan-and-billing.svg';
import strings from './account-users/account-settings-users.strings';

// A composable to help keep down component size
export function useNavbarOverrideItems() {
  // remove this when other pages of this MFE are implemented
  let oldMode: NavigationBarMode | undefined;
  const unsubs: Unsub[] = [];

  onBeforeMount(() => {
    oldMode = navigationBarMode.value;
    navigationBarMode.value = NavigationBarMode.Hidden;
    function reg(item: NavigationItem) {
      unsubs.push(navigationItemsOverride.register(item));
    }

    reg({
      key: 'USER_PROFILE',
      text: strings.userProfileLink,
      route: '/v1/account-settings/user-profile',
      iconUrl: userIcon,
    });

    reg({
      key: 'ACCOUNT_USER',
      text: strings.accountUsersLink,
      route: '/v1/account-settings/users',
      iconUrl: usersIcon,
    });

    reg({
      key: 'PLAN_AND_BILLING',
      text: strings.planAndBillingLink,
      route: '/v1/account-settings/plan-and-billing',
      iconUrl: planAndBillingIcon,
    });
  });

  onUnmounted(() => {
    if (oldMode) navigationBarMode.value = oldMode;
    unsubs.forEach((unsub) => unsub());
  });
}
