<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { accountSettingsUsersGateway } from '@msl/account-settings-users-gateway-sdk';
import { /* auth4Sdks, */ currentUser, trackEvent } from '@mfl/platform-shell';
import { WsButton } from '@mfl/common-components';
import {
  GetUsersResponse,
  Admin,
  INVITE_LIMIT,
  ROLES_PERMISSIONS_ADVANCED_ENTITLEMENT,
} from '@msl/account-settings-users-gateway-sdk';

import WsGrid from './components/ws-grid/ws-grid.vue';
import RowActions from './components/row-actions.vue';
import NameCellRenderer from './components/name-cell-renderer.vue';
import { openAdminFormDialog } from './components/dialogs/admin-form/admin-form';
import { openVerifyEmailDialog } from './components/dialogs/email-verify/email-verify';
import { accountSettingsUsersState } from './account-settings-users-state';
import { accountSettingsState } from '../account-settings-state';
import { openUpgradeDialog } from './components/dialogs/upgrade/upgrade';
import strings from './account-settings-users.strings';
import { ColDef } from './components/ws-grid/ag-grid-theme-builder';

const loading = ref<boolean>(false);
const dataLoaded = ref<boolean>(false);
const adminUsers = ref<GetUsersResponse['admins']>([]);
const adminsRows = ref<Admin[]>([]);

const columnDefs = ref<ColDef[]>([
  {
    field: 'name',
    cellRenderer: 'NameCellRenderer',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cellRendererParams: (params: any) => {
      return {
        expiration: adminUsers.value?.find(
          (au) => au.email === params?.data?.email
        )?.expiration,
      };
    },
  },
  { field: 'email' },
  {
    field: 'role',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cellRenderer: (params: any) => {
      const admin: Admin =
        adminUsers.value?.find((au) => au.email === params?.data?.email) ?? {};

      return roleGridText(admin);
    },
  },
  {
    field: 'organization',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltipValueGetter: (params: any) => {
      gridTooltipValueGetter(params?.data?.email);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cellRenderer: (params: any) => {
      const admin: Admin =
        adminUsers.value?.find((au) => au.email === params?.data?.email) ?? {};

      return organizationsGridText(admin) ?? [];
    },
  },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: 'RowActions',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cellRendererParams: (params: any) => {
      return {
        currentUserIsOwner:
          (accountSettingsState.accountOwnerId || '') ==
          accountSettingsState.userId,
        admin: adminUsers.value?.find((au) => au.email === params?.data?.email),
      };
    },
    maxWidth: 80,
    minWidth: 80,
  },
]);

function adminsRowsData() {
  return (
    adminUsers.value?.map((admin) => ({
      name: admin.name,
      email: admin.email,
      /*
        role and organization texts is rendered by a cellRenderer function (columnDefs),
        those values are fallback values just in case.
      */
      role: admin.permissions?.[0]?.role,
      organization: organizationsGridText(admin),
    })) || []
  );
}

function gridTooltipValueGetter(email: string) {
  const orgs =
    adminUsers.value
      ?.find((au) => au.email === email)
      ?.permissions?.map((p) => p.name) || [];
  if (orgs.length > 1) return orgs.toString().replaceAll(',', ', ');
  return null;
}

function roleGridText(admin: Admin) {
  if (admin.isAccountOwner) return strings.owner;
  return (
    strings[admin.permissions?.[0]?.role as keyof typeof strings] ??
    admin.permissions?.[0]?.role
  );
}

function organizationsGridText(admin: Admin) {
  const orgs = admin.permissions?.map((p) => p.name) || [];
  if (orgs.length > 1) return `${orgs[0]} + ${orgs.length - 1}`;
  return orgs && orgs[0] === 'allOrganizations'
    ? strings.allOrganizations
    : orgs.toString();
}

const gridData = computed(() => {
  return {
    aid: 'ACCOUNT_SETTINGS_USERS_GRID',
    rowData: adminsRows.value,
    columnDefs: columnDefs.value,
    loading: loading.value,
    // there should be always at least one user (owner), empty is always due to an error
    overlayNoRowsTemplate: strings.gridDataError,
    customComponents: {
      RowActions,
      NameCellRenderer,
    },
  };
});

async function addAdmin() {
  if (
    (adminUsers.value?.length ?? 0) >= INVITE_LIMIT &&
    !currentUser.isEntitledTo(ROLES_PERMISSIONS_ADVANCED_ENTITLEMENT)
  ) {
    openUpgradeDialog('invite');
    return;
  }

  if (parseInt(accountSettingsState.trustPolicyLevel || '0') < 2) {
    openVerifyEmailDialog();
    return;
  }

  const admin: Admin | undefined = await openAdminFormDialog();
  if (admin) loadData();
}

async function loadAndHandleAdminsData() {
  const { admins } = await accountSettingsUsersGateway.getUsers({});
  adminUsers.value = admins;
  adminsRows.value = adminsRowsData();
}

async function loadAndHandleRolesData() {
  const { roles } = await accountSettingsUsersGateway.getRoles({});
  accountSettingsUsersState.roles = roles ?? [];
}

async function loadAndHandleOrganizationsData() {
  const { organizations } = await accountSettingsUsersGateway.getOrganizations(
    {}
  );
  accountSettingsUsersState.organizations = organizations ?? [];
}

async function loadData() {
  loading.value = true;
  try {
    await Promise.all([
      loadAndHandleRolesData(),
      loadAndHandleOrganizationsData(),
    ]);
    /*
      loadAndHandleAdminsData is out of the Promise.all so in case Roles and Organizations not loaded
      we won't load and show the admins and have problems editing them due to missing data.
    */
    await loadAndHandleAdminsData();
    dataLoaded.value = true;
  } catch (e) {
    console.error('Faild to load data', e);
  }
  loading.value = false;
}

// watch when owner set other admin user to be the account owner
watch(
  () => accountSettingsState.accountOwnerId,
  (val) => {
    if (val !== accountSettingsState.userId) loadAndHandleAdminsData();
  }
);

onMounted(async () => {
  /*
    init is commented for now until a dedicated if GW will be created for
    the MFE and getInfo end point will be move there
  */
  // await accountSettingsUsersGateway.init(auth4Sdks());

  await loadData();

  trackEvent({
    eventName: 'account_users_page_view',
    eventMetadata: {
      attributes: {
        plan: accountSettingsState.plans?.toString(),
        total_users: adminUsers.value?.length,
      },
    },
  });
});
</script>

<template>
  <div class="account-users">
    <div class="account-users__header">
      <h1 class="text-2xl font-bold">{{ strings.pageHeader }}</h1>
      <div class="account-users__header__actions">
        <WsButton
          aid="WHAT_ARE_RULES_BUTTON"
          variant="text"
          href="https://support.wisestamp.com/hc/en-us/articles/12142434846237-Roles-and-Permissions"
          target="_blank"
          size="sm"
          icon="fa-light fa-arrow-up-right-from-square"
          icon-end
        >
          {{ strings.whatAreRoles }}
        </WsButton>

        <!-- disabled if loading or data loading failed -->
        <WsButton
          size="sm"
          aid="ADD_USER_BUTTON"
          :disabled="loading || !dataLoaded"
          @click="addAdmin"
        >
          {{ strings.addUser }}
        </WsButton>
      </div>
    </div>
    <WsGrid v-bind="gridData" />
  </div>
</template>

<style lang="scss" scoped>
.account-users {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &__actions {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }
}
</style>
