<script setup lang="ts">
import { onMounted, ref } from 'vue';

import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
  toast,
  ToastStatus,
  vLoading,
} from '@mfl/common-components';
import { navigateTo } from '@mfl/framework';
import { WEBAPP_BASE_URL } from '@mfl/platform-shell';

import { accountSettingsUsersGateway } from '@msl/account-settings-users-gateway-sdk';
import { trackEvent } from '@mfl/platform-shell';

import { accountSettingsState } from '../../../../account-settings-state';
import strings from './email-verify.strings';

const { dialog } = defineProps<{
  dialog: IDialog<undefined, undefined>;
}>();

const resendVerificationInProcess = ref<boolean>(false);

async function resendVerificationEmail() {
  if (resendVerificationInProcess.value) return;
  resendVerificationInProcess.value = true;
  let success: boolean | undefined = false;
  try {
    const response = await accountSettingsUsersGateway.sendVerificationEmail(
      {}
    );
    success = response.success;
  } catch (e) {
    console.error('resendVerificationEmail - Failed', e);
  }

  toast({
    aid: 'VERIFY_EMAIL_TOAST',
    status: success ? ToastStatus.Completed : ToastStatus.Error,
    message: success ? strings.toastSuccess : strings.toastError,
  });

  resendVerificationInProcess.value = false;
  if (success) dialog.close();
}

function redirectToProfile() {
  // TODO: once profile page is implemented, replace this with a proper navigation
  navigateTo(`${WEBAPP_BASE_URL}/profile`, { reload: true });
}

onMounted(() => {
  trackEvent({
    eventName: 'verify_email_modal_show',
    eventMetadata: {
      attributes: {
        page: 'account-settings/users',
      },
    },
  });
});
</script>
<template>
  <div v-loading="resendVerificationInProcess" class="verify-email-dialog">
    <WsDialogSection class="text-sm">
      <p>
        {{
          strings.contentTopSection.replace(
            '\{\{email\}\}',
            accountSettingsState.userEmail || ''
          )
        }}
      </p>
      <p>
        {{ strings.contentNotReceivedQuestion }}
        <br />
        <a
          href="#"
          class="text-underline"
          aid="VERIFY_EMAIL_RESEND"
          @click.prevent="resendVerificationEmail"
        >
          {{ strings.resendLinkText }}
        </a>
        {{ strings.or }}
        <a
          :href="`${WEBAPP_BASE_URL}/profile`"
          aid="VERIFY_EMAIL_CHANGE_EMAIL"
          class="text-underline"
          @click.prevent="redirectToProfile"
        >
          {{ strings.changeEmailLinkText }}
        </a>
        <br />{{ strings.tip }}
      </p>
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        :label="strings.ok"
        aid="VERIFY_EMAIL_OK"
        @click="dialog.close()"
      />
    </WsDialogActions>
  </div>
</template>

<style lang="scss" scoped>
.verify-email-dialog {
  width: 460px;

  p:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
