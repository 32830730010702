<script setup lang="ts">
import { WsBadge } from '@mfl/common-components';
import { defineProps, ref, onMounted } from 'vue';
import { Admin } from '@msl/account-settings-users-gateway-sdk';
import strings from '../account-settings-users.strings';

const props = defineProps<{
  params: Record<string, unknown>;
}>();
const admin = ref<Admin>(props.params?.data as Admin);
const expiration = ref<number | undefined>(props.params?.expiration as number);

const expirationBadgeLabel = ref<string | undefined>(undefined);
const expired = ref<boolean>(false);

function setExpirationBadge() {
  if (!expiration.value) return;
  expired.value = expiration.value < new Date().getTime();
  expirationBadgeLabel.value = expired.value
    ? strings.expired
    : strings.pending;
}

onMounted(() => {
  setExpirationBadge();
});
</script>

<template>
  <div class="name-cell-renderer">
    <span aid="ACCOUNT_SETTINGS_GRID_NAME">{{ admin.name }}</span>
    <WsBadge
      v-if="expirationBadgeLabel"
      aid="ACCOUNT_SETTINGS_GRID_EXPIRATION_BADGE"
      :label="expirationBadgeLabel"
      size="sm"
      :color="expired ? 'gray' : 'primary'"
    />
  </div>
</template>

<style lang="scss" scoped>
.name-cell-renderer {
  display: flex;
  align-items: center;
  width: 160px;
  gap: 5px;

  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ws-badge {
    margin-left: auto;
    flex-shrink: 0;
  }
}
</style>
