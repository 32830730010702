export default {
  accountUsersLink: 'Users',
  addUser: 'Add user',
  admin: 'Admin',
  allOrganizations: 'All organizations',
  cancel: 'Cancel',
  confirm: 'Confirm',
  delete: 'Delete',
  deleteAdminError: 'Failed to Deleted {{email}}',
  deleteAdminPromptHeader: 'Delete User',
  deleteAdminPromptQuestion: 'Are you sure you want to delete {{email}}?',
  deleteAdminSuccess: 'Successfully Deleted {{email}}',
  designer: 'Designer',
  domain_manager: 'Organization manager', // the roles are loded from DB and it saved with "_" , please do not change!
  edit: 'Edit',
  email: 'Email',
  expired: 'EXPIRED',
  gridDataError: 'Failed to load data',
  header: 'Welcome to account-settings',
  hr: 'HR',
  inviteAdminError: 'Failed to send the Invite',
  inviteAdminSuccess: 'Invite was succesfully sent',
  it: 'IT',
  marketer: 'Marketer',
  name: 'Name',
  ok: 'ok',
  organization: 'Organization',
  organizations: 'Organizations',
  owner: 'Owner',
  pageHeader: 'Account users',
  pending: 'PENDING',
  planAndBillingLink: 'Plan & Billing',
  resend: 'Resend',
  role: 'Role',
  save: 'Save',
  userProfileLink: 'Profile',
  whatAreRoles: 'Learn more about roles',
};
