<script setup lang="ts">
import { defineProps, ref } from 'vue';

import {
  Admin,
  CreateInviteRequest,
  accountSettingsUsersGateway,
} from '@msl/account-settings-users-gateway-sdk';
import {
  IDialog,
  prompt,
  PromptResult,
  WsSelect,
  WsSelectOption,
  WsButton,
  WsDialogSection,
  WsDialogActions,
  WsInput,
  WsToggle,
  WsDialogForm,
  WsBadge,
  toast,
  ToastStatus,
} from '@mfl/common-components';
import { currentUser } from '@mfl/platform-shell';
import { Permission } from '@msl/account-settings-users-gateway-sdk';

import strings from './admin-form.strings';
import { openUpgradeDialog } from '../upgrade/upgrade';
import { accountSettingsUsersState } from '../../../account-settings-users-state';
import { accountSettingsState } from '../../../../account-settings-state';
import { validateEmailRegEx } from '../../../../consts';

type Organization = {
  id: string;
  domain: string;
  displayName: string;
};

const errorMessages = {
  name: strings.adminFormErrorName,
  email: strings.adminFormErrorEmail,
  role: strings.adminFormErrorRole,
  organization: strings.adminFormErrorOrganization,
};

const allOrganizationsValue: Organization = {
  id: 'all',
  domain: 'all',
  displayName: strings.allOrganizations,
};

const upgradeRequired = !currentUser.isEntitledTo(
  'roles-and-permissions.advanced'
);

const accountOrganizations = JSON.parse(
  JSON.stringify(accountSettingsUsersState.organizations)
);

// owner is not a role...
const roles = accountSettingsUsersState.roles.filter((r) => r !== 'owner');

const { dialog } = defineProps<{
  dialog: IDialog<Admin, Admin>;
}>();

const organizationsInvalidMessage = ref<string | undefined>(undefined);
const roleInvalidMessage = ref<string | undefined>(undefined);
const allOrganizations = ref<boolean>(false);
const proccessing = ref<boolean>(false);
const admin = ref<Admin>(dialog.input);
const role = ref<(typeof accountSettingsUsersState.roles)[number]>(
  admin.value.permissions?.[0]?.role ?? ''
);
const organizations = ref<Organization[]>([]);

// if the current user is the owner and editing an existing admin (not a new invite)
const canSetAsOwner =
  accountSettingsState.accountOwnerId === accountSettingsState.userId &&
  admin.value.id;

// pull organizations from admin permissions
if (admin.value.permissions?.[0]?.id === 'all') {
  allOrganizations.value = true;
  organizations.value = [allOrganizationsValue];
} else {
  organizations.value = accountOrganizations.filter(
    (org: Record<string, unknown>) =>
      admin.value.permissions?.some((p) => p.id === org.id)
  );
}

function validateForm() {
  roleInvalidMessage.value = undefined;
  organizationsInvalidMessage.value = undefined;
  let valid = true;

  // validate role
  if (!role.value) {
    roleInvalidMessage.value = errorMessages.role;
    valid = false;
  }
  // validate organization
  if (!organizations.value.length) {
    organizationsInvalidMessage.value = errorMessages.organization;
    valid = false;
  }
  return valid;
}

async function saveAdmin() {
  if (!validateForm()) return;
  proccessing.value = true;
  let succesfully = true;
  // if an existing admin
  if (admin.value.id) {
    // verify owner change
    if (role.value === 'owner') {
      const result = await prompt({
        aid: 'CHANGE_OWNER_CONFIRM',
        header: strings.adminFormChangeOwnerPromptHeader,
        question: strings.adminFormChangeOwnerPromptQuestion.replace(
          '{{name}}',
          admin.value.name ?? ''
        ),
        primaryButtonText: strings.confirm,
        secondaryButtonText: strings.cancel,
        persistent: true,
      });

      if (result === PromptResult.Secondary) {
        proccessing.value = false;
        return;
      }
      admin.value.isAccountOwner = true;
    }
    succesfully = await updateAdmin();
  } else {
    succesfully = await inviteAdmin();
  }

  proccessing.value = false;
  if (succesfully) closeDialog(admin.value);
}

async function updateAdmin(): Promise<boolean> {
  // building permissions for save
  if (organizations.value.find((org) => org.id === 'all')) {
    admin.value.permissions = [
      {
        id: 'all',
        name: strings.allOrganizations,
        role: role.value === 'owner' ? 'admin' : role.value,
        domain: 'all',
      },
    ];
  } else {
    admin.value.permissions = organizations.value.map((org) => {
      return {
        id: org.id,
        name: org.displayName,
        domain: org.domain,
        role: role.value,
      } as Permission;
    }, [] as Permission[]);
  }

  try {
    await accountSettingsUsersGateway.updateUser({
      ...admin.value,
    });

    if (admin.value.isAccountOwner)
      accountSettingsState.accountOwnerId = admin.value.id;

    toast({
      aid: 'ACCOUNT_SETTINGS_UPDATE_TOAST',
      message: strings.adminFormSaved,
    });
    return true;
  } catch {
    toast({
      aid: 'ACCOUNT_SETTINGS_UPDATE_TOAST',
      status: ToastStatus.Error,
      message: strings.adminFormFailSave,
    });
    return false;
  }
}

async function inviteAdmin(): Promise<boolean> {
  const invitee: CreateInviteRequest = {
    inviteeName: admin.value.name,
    inviteeEmail: admin.value.email,
    roles: [],
  };

  if (organizations.value.find((org) => org.id === 'all')) {
    invitee.roles?.push({ role: role.value, domain: 'all' });
  } else {
    organizations.value.forEach((org) => {
      invitee.roles?.push({
        role: role.value,
        domain: org.id as string,
      });
    });
  }

  try {
    await accountSettingsUsersGateway.invite(invitee);
    toast({
      aid: 'ACCOUNT_SETTINGS_INVITE_TOAST',
      message: strings.adminFormInviteSent,
    });
    return true;
  } catch {
    toast({
      aid: 'ACCOUNT_SETTINGS_INVITE_TOAST',
      status: ToastStatus.Error,
      message: strings.adminFormInviteFail,
    });
    return false;
  }
}

function roleLabel(val: keyof typeof strings) {
  if (admin.value.isAccountOwner) return strings.owner;
  return strings[val] as string;
}

function closeDialog(output: Admin | undefined = undefined) {
  dialog.close(output);
}

function checkIfAdmin(e: unknown) {
  roleInvalidMessage.value = undefined;
  if (/^(owner)$|^(admin)$/.test(e as string)) {
    organizations.value = [allOrganizationsValue];
    allOrganizations.value = true;
  }
}

function toggleAllOrganizations(e: boolean) {
  organizationsInvalidMessage.value = undefined;
  organizations.value = e
    ? [allOrganizationsValue]
    : accountOrganizations.length === 1
      ? // if there is only one organization, select it by default
        [accountOrganizations[0]]
      : [];
}

function upgrade(e: PointerEvent) {
  e.stopPropagation();
  openUpgradeDialog('edit');
  closeDialog();
}
</script>

<template>
  <WsDialogForm @submit="saveAdmin">
    <WsDialogSection class="admin-form">
      <label
        for="admin_name"
        class="text-sm mb-1.5 inline-block font-semibold"
        >{{ strings.name }}</label
      >
      <WsInput
        id="admin_name"
        v-model="admin.name"
        :placeholder="strings.name"
        :rules="[(v) => (!v ? errorMessages.name : true)]"
        aid="ADMIN_FORM_NAME"
      />

      <label
        for="admin_email"
        class="text-sm mb-1.5 inline-block font-semibold"
        >{{ strings.email }}</label
      >
      <WsInput
        id="admin_email"
        v-model="admin.email"
        :placeholder="strings.email"
        aid="ADMIN_FORM_EMAIL"
        :rules="[
          (v) => (!validateEmailRegEx.test(v) ? errorMessages.email : true),
        ]"
      />

      <div class="admin-form__selects">
        <div>
          <label class="text-sm mb-1.5 inline-block">
            {{ strings.role }}
            <WsButton
              aid="ROLE_INFO"
              variant="text"
              target="_blank"
              href="https://support.wisestamp.com/hc/en-us/articles/12142434846237-Roles-and-Permissions"
            >
              <span class="fa-circle-info fa-regular text-gray-400"></span>
            </WsButton>
          </label>

          <WsSelect
            v-model="role as (typeof accountSettingsUsersState.roles)[number]"
            aid="ADMIN_FORM_ROLE"
            :error="roleInvalidMessage"
            :disabled="admin.isAccountOwner"
            :option-label="roleLabel"
            :option-key="(v) => v"
            @update:model-value="checkIfAdmin"
          >
            <WsSelectOption
              v-if="canSetAsOwner"
              key="admin"
              value="owner"
              class="cursor-pointer"
            >
              {{ strings.owner }}
            </WsSelectOption>

            <WsSelectOption
              v-for="r in roles"
              :key="r"
              :value="r"
              :disabled="upgradeRequired && !['admin', 'owner'].includes(r)"
            >
              <div
                v-if="upgradeRequired"
                class="flex justify-between cursor-pointer"
                @click="
                  !['admin', 'owner'].includes(r)
                    ? upgrade($event as PointerEvent)
                    : () => {}
                "
              >
                {{ strings[r as keyof typeof strings] }}
                <WsBadge
                  v-if="r !== 'admin'"
                  aid="_"
                  :label="strings.upgrade"
                  size="sm"
                  icon="fa-regular fa-circle-arrow-up"
                />
              </div>
              <div v-else class="flex justify-between">
                {{ strings[r as keyof typeof strings] }}
              </div>
            </WsSelectOption>
          </WsSelect>
        </div>

        <div v-if="!admin.isAccountOwner && !/^(owner)$|^(admin)$/.test(role)">
          <div class="flex justify-between">
            <label class="text-sm mb-1.5 inline-block">{{
              strings.organizations
            }}</label>

            <div class="flex flex-center gap-2 mb-1.5">
              <label class="text-sm">{{
                allOrganizationsValue.displayName
              }}</label>
              <WsToggle
                v-model="allOrganizations"
                aid="ADMIN_FORM_ORGANIZATIONS_ALL"
                @update:model-value="toggleAllOrganizations"
              />
            </div>
          </div>

          <WsSelect
            v-model="organizations"
            aid="ADMIN_FORM_ORGANIZATIONS"
            multiple
            checkboxes
            chips
            :error="organizationsInvalidMessage"
            :disabled="allOrganizations || accountOrganizations.length === 1"
            :option-label="(v) => (v.displayName ?? v.domain) as string"
            :option-key="(v) => v.id as string"
            @update:model-value="organizationsInvalidMessage = undefined"
          >
            <WsSelectOption
              v-for="org in accountOrganizations"
              :key="org.id"
              :value="org"
            >
              {{ org.displayName }}
            </WsSelectOption>
          </WsSelect>
        </div>
      </div>
    </WsDialogSection>

    <WsDialogActions>
      <WsButton
        :label="strings.cancel"
        aid="ADMIN_FORM_CLOSE"
        variant="outlined"
        color="gray-500"
        :disabled="proccessing"
        @click="closeDialog()"
      />

      <WsButton
        :label="strings.save"
        aid="ADMIN_FORM_SAVE"
        type="submit"
        :loading="proccessing"
        :disabled="proccessing"
      />
    </WsDialogActions>
  </WsDialogForm>
</template>

<style lang="scss" scoped>
.admin-form {
  --ws-input-font-size: 0.8125rem;
  --ws-input-height: 45px;
  --ws-input-border-radius: 4px;
  width: 400px;

  &__selects {
    display: flex;
    flex-direction: column;
    gap: 20px;

    [aid='ADMIN_FORM_ORGANIZATIONS'] {
      width: 100%;
    }
  }

  &__organization-trigger {
    display: flex;
    height: var(--ws-input-height);
    width: 100%;
    gap: 12px;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;
    text-align: start;
    border: 1px solid rgb(var(--color-gray-200));
    border-radius: var(--ws-input-border-radius);
  }

  &__organization-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
