<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue';
import { GridApi, RowNode } from './ws-grid/ag-grid-theme-builder';
import { auth4Sdks } from '@mfl/platform-shell';
import {
  Admin,
  CreateInviteRequest,
  accountSettingsUsersGateway,
} from '@msl/account-settings-users-gateway-sdk';
import {
  WsMenu,
  WsIconButton,
  WsMenuItem,
  prompt,
  PromptResult,
  toast,
  ToastStatus,
} from '@mfl/common-components';
import strings from '../account-settings-users.strings';
import { openAdminFormDialog } from './dialogs/admin-form/admin-form';

const props = defineProps<{
  params: Record<string, unknown>;
}>();

const { api, node } = props.params;
const admin = ref<Admin>(props.params.admin as Admin);
const currentUserIsOwner = props.params.currentUserIsOwner;

async function deleteAdmin() {
  if (admin.value.isAccountOwner) {
    console.error("Can't delete account owner");
    return;
  }

  const result = await prompt({
    aid: 'DELETE_USER_CONFIRM',
    header: strings.deleteAdminPromptHeader,
    question: strings.deleteAdminPromptQuestion.replace(
      '{{email}}',
      admin.value.email ?? ''
    ),
    primaryButtonText: strings.delete,
    primaryButtonColor: 'danger',
    secondaryButtonText: strings.cancel,
  });

  if (result === PromptResult.Primary) {
    let successfully: boolean = true;
    // if invite
    if (!admin.value.id) {
      try {
        await accountSettingsUsersGateway.revoke({
          inviteeEmail: admin.value.email,
        });
      } catch {
        successfully = false;
      }
    } else {
      // existing user
      try {
        await accountSettingsUsersGateway.deleteUser({
          userId: admin.value.id,
        });
      } catch {
        successfully = false;
      }
    }

    const toastStatus = successfully
      ? ToastStatus.Completed
      : ToastStatus.Error;
    const toastMessageKey = successfully
      ? 'deleteAdminSuccess'
      : 'deleteAdminError';

    toast({
      aid: 'ACCOUNT_SETTINGS_DELETE_ADMIN_TOAST',
      status: toastStatus,
      message: strings[toastMessageKey].replace(
        '{{email}}',
        admin.value.email ?? ''
      ),
    });

    if (successfully)
      (api as GridApi).applyTransaction({ remove: [(node as RowNode).data] });
  }
}

async function editAdmin() {
  const resp = (await openAdminFormDialog(admin.value)) || {};
  admin.value = Object.assign(admin.value, resp);
  updateRowData();
}

function setInvitePermissions() {
  // if all organizations
  if (admin.value.permissions?.find((org) => org.id === 'all')) {
    return [
      {
        role: admin.value.permissions?.[0]?.role,
        domain: 'all',
      },
    ];
  }

  return admin.value.permissions?.map((org) => {
    return {
      role: org.role,
      domain: org.id as string,
    };
  });
}

async function resendInvite() {
  const invitee: CreateInviteRequest = {
    inviteeName: admin.value.name,
    inviteeEmail: admin.value.email,
    roles: setInvitePermissions(),
  };

  try {
    const { expiration } = await accountSettingsUsersGateway.invite(invitee);
    admin.value.expiration = expiration;
    updateRowData();
    toast({
      aid: 'ACCOUNT_SETTINGS_REINVITE_TOAST',
      message: strings.inviteAdminSuccess,
    });
  } catch (e) {
    toast({
      aid: 'ACCOUNT_SETTINGS_REINVITE_TOAST',
      status: ToastStatus.Error,
      message: strings.inviteAdminError,
    });

    console.error('Resend invite failed', e);
  }
}

function updateRowData() {
  const updatedData = {
    name: admin.value.name,
    email: admin.value.email,
    role: admin.value.isAccountOwner
      ? 'Owner'
      : admin.value.permissions?.[0].role,
    organization: (() => {
      const orgs = admin.value.permissions?.map((p) => p.name) || [];
      return orgs.length > 1 ? `${orgs[0]} + ${orgs.length - 1}` : orgs;
    })().toString(),
  };

  (node as RowNode).setData({ ...(node as RowNode).data, ...updatedData });
  (api as GridApi).refreshCells({ rowNodes: [node as RowNode], force: true });
}

onMounted(async () => {
  await accountSettingsUsersGateway.init(auth4Sdks());
});
</script>

<template>
  <WsIconButton
    v-if="currentUserIsOwner || !admin.isAccountOwner"
    variant="text"
    color="gray-400"
    aid="ACCOUNT_USER_MENU_BUTTON"
  >
    <span class="fa-solid fa-ellipsis" />
    <WsMenu aid="ACCOUNT_USER_MENU">
      <!-- Edit -->
      <WsMenuItem
        v-if="admin.id"
        aid="ACCOUNT_USER_MENU_EDIT"
        icon="fa-light fa-pen"
        @click="editAdmin"
      >
        {{ strings.edit }}
      </WsMenuItem>

      <!-- Delete -->
      <WsMenuItem
        v-if="!admin.isAccountOwner"
        aid="ACCOUNT_USER_MENU_DELETE"
        icon="fa-light fa-trash-can"
        @click="deleteAdmin"
      >
        {{ strings.delete }}
      </WsMenuItem>

      <!-- resend invite -->
      <WsMenuItem
        v-if="!admin.id"
        aid="ACCOUNT_USER_MENU_RESEND"
        icon="fa-light fa-paper-plane"
        @click="resendInvite"
      >
        {{ strings.resend }}
      </WsMenuItem>
    </WsMenu>
  </WsIconButton>
</template>

<style lang="scss" scoped></style>
