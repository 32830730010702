export default {
  changeEmailLinkText: 'change email address',
  ok: 'ok',
  or: 'or',
  tip: "Tip: Don't forget to check your spam folder.",
  header: 'Verify your email address',
  resendLinkText: 'Resend a verification email',
  toastError: 'Failed to send email, please try again.',
  toastSuccess: 'Email sent succesfully, check your mail box.',
  contentTopSection:
    'A verification email was sent to {{email}} Please check your inbox and click the verification link.',
  contentNotReceivedQuestion: "Haven't received an email?",
};
