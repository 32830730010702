<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { RouterView } from 'vue-router';
import { Unsub } from '@mfl/framework';
import { auth4Sdks } from '@mfl/platform-shell';
import { headerComponents, showLogo } from '@mfe/core-header';
import { accountSettingsUsersGateway } from '@msl/account-settings-users-gateway-sdk';

import { useNavbarOverrideItems } from './account-settings-navigation';
import AccountSettingsHeaderBackButton from './account-settings-header-back-button.vue';
import {
  accountSettingsState,
  initSettingsState,
} from './account-settings-state';

useNavbarOverrideItems();

let unsubHeader: Unsub | undefined = undefined;

const infoLoaded = ref<boolean>(false);

onMounted(async () => {
  unsubHeader = headerComponents.register({
    component: AccountSettingsHeaderBackButton,
    key: 'ACCOUNT_SETTINGS_BACK_BUTTON',
  });
  showLogo(false);

  await initSettingsState();
  await accountSettingsUsersGateway.init(auth4Sdks());
  const info = await accountSettingsUsersGateway.getInfo({});
  Object.assign(accountSettingsState, info);
  infoLoaded.value = true;
});

onUnmounted(() => {
  if (unsubHeader) unsubHeader();
  showLogo(true);
});
</script>

<template>
  <div class="account-settings-container">
    <RouterView v-if="infoLoaded" />
  </div>
</template>

<style scoped>
.account-settings-container {
  display: flex;
  margin: 24px;
  flex-direction: column;
}
</style>
