import { openDialog } from '@mfl/common-components';
import { Admin } from '@msl/account-settings-users-gateway-sdk';
import adminForm from './admin-form.vue';
import strings from './admin-form.strings';

const emptyAdmin: Admin = {
  id: '',
  email: '',
  name: '',
  permissions: [
    {
      id: 'all',
      name: strings.allOrganizations,
      role: 'admin',
      domain: 'all',
    },
  ],
};

/*
  the form is both for a new invite and editing an existing admin user
  the default is an empty Admin and if input (existing admin) sent
  than appying it to the input sent to the component
*/
export async function openAdminFormDialog(
  input?: Admin | undefined
): Promise<Admin | undefined> {
  return await openDialog<Admin, Admin>({
    aid: 'ACCOUNT_SETTINGS_ADMIN_FORM',
    headerText: input?.id
      ? strings.adminFormEditHeader
      : strings.adminFormAddHeader,
    component: adminForm,
    input: { ...emptyAdmin, ...input },
  });
}
