import { openDialog } from '@mfl/common-components';
import component from './email-verify.vue';
import strings from './email-verify.strings';

export async function openVerifyEmailDialog() {
  await openDialog({
    aid: 'ACCOUNT_SETTINGS_VERIFY_EMAIL_DIALOG',
    headerText: strings.header,
    component,
  });
}
