import { createRouter, createWebHistory } from 'vue-router';

import User from './user-profile/user-profile.vue';
import Users from './account-users/account-users.vue';
import PlanAndBilling from './plan-and-billing/plan-and-billing.vue';

const routes = [
  {
    name: 'account-settings',
    path: '/v1/account-settings',
    redirect: { name: 'account-settings-users' },
    children: [
      {
        name: 'account-settings-users',
        path: 'users',
        component: Users,
      },
      {
        name: 'account-settings-user-profile',
        path: 'user-profile',
        component: User,
      },
      {
        name: 'account-settings-plan-and-billing',
        path: 'plan-and-billing',
        component: PlanAndBilling,
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(''),
  routes,
});
