<script lang="ts" setup>
import { computed } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import {
  ColDef,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy,
  DomLayoutType,
} from 'ag-grid-community';

import { WSAgTheme } from './ag-grid-theme-builder';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  rowData: Record<string, unknown>[];
  columnDefs: ColDef[];
  aid: string;
  customComponents?: Record<string, unknown>;
  autoSizeStrategy?:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
    | undefined;
  noRowsOverlayComponent?: string;
  overlayNoRowsTemplate?: string;
  loadingOverlayComponent?: unknown;
  overlayLoadingTemplate?: string;
  loading?: boolean;
}>();

const attrs = computed<{
  theme: typeof WSAgTheme;
  columnDefs: ColDef[];
  tooltipShowDelay: number;
  rowData: Record<string, unknown>[];
  domLayout: DomLayoutType;
  suppressCellFocus: boolean;
  defaultColDef: { flex: number; suppressMovable: boolean; resizable: boolean };
  class: string;
  aid: string;
  loading?: boolean;
  loadingOverlayComponent?: unknown;
  overlayLoadingTemplate?: string;
  autoSizeStrategy?:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
    | undefined;
  noRowsOverlayComponent?: unknown;
  overlayNoRowsTemplate?: string;
}>(() => ({
  theme: WSAgTheme,
  columnDefs: props.columnDefs,
  tooltipShowDelay: 500,
  rowData: props.rowData,
  domLayout: 'autoHeight' as DomLayoutType,
  suppressCellFocus: true,
  defaultColDef: { flex: 1, suppressMovable: true, resizable: false },
  class: 'ws-grid',
  aid: props.aid,
  loading: props.loading,
  loadingOverlayComponent: props.loadingOverlayComponent,
  overlayLoadingTemplate: props.overlayLoadingTemplate,
  autoSizeStrategy: props.autoSizeStrategy,
  noRowsOverlayComponent: props.noRowsOverlayComponent,
  overlayNoRowsTemplate: props.overlayNoRowsTemplate,
}));

defineExpose(props.customComponents);
</script>

<template>
  <AgGridVue v-bind="attrs" />
</template>

<style lang="scss" scoped></style>
