import { openDialog } from '@mfl/common-components';
import component from './upgrade-dialog.vue';
import strings from './upgrade.strings';

export function openUpgradeDialog(source: 'invite' | 'edit') {
  openDialog({
    aid: 'ACCOUNT_SETTINGS_UPGRADE_DIALOG',
    headerText: strings.upgradeDialogHeader,
    component,
    showTopSeparator: false,
    input: source,
  });
}
