<script setup lang="ts">
import { onMounted } from 'vue';

import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';
import { trackEvent, WEBAPP_BASE_URL } from '@mfl/platform-shell';
import { navigateTo } from '@mfl/framework';

import strings from './upgrade.strings';

const props = defineProps<{
  dialog: IDialog<'invite' | 'edit'>;
}>();

const message =
  props.dialog.input === 'invite'
    ? strings.upgradeDialogTextInvite
    : strings.upgradeDialogTextEdit;

function redirectToDemo() {
  try {
    // prevent an error with the analytics from holding the back the user from redirecting to schedule a demo
    sendEvent('team_upgrade_modal_user_clicked_schedule_demo');
  } catch (e) {
    console.error(e);
  }
  redirectTo(
    `${WEBAPP_BASE_URL}/scheduler?ref=invite_users&o=ws1_invite_dialog`
  );
}

function redirectToUpgrade() {
  try {
    // prevent an error with the analytics from holding the back the user from redirecting to upgrade
    sendEvent('team_upgrade_modal_user_clicked_upgrade');
  } catch (e) {
    console.error(e);
  }
  redirectTo(`${WEBAPP_BASE_URL}/?upgradeNow`);
}

function redirectTo(path: string) {
  navigateTo(path, { reload: true });
}

function sendEvent(eventName: string) {
  trackEvent({
    eventName,
    eventMetadata: {
      attributes: {
        page: 'account-settings/users',
      },
    },
  });
}

onMounted(() => {
  sendEvent('team_upgrade_modal_show');
});
</script>

<template>
  <div class="upgrade-dialog">
    <WsDialogSection>
      {{ message }}
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        :label="strings.scheduleADemo"
        variant="outlined"
        :href="`${WEBAPP_BASE_URL}/scheduler?ref=invite_users&o=ws1_invite_dialog`"
        aid="ACCOUNT_SETTINGS_UPGRADE_DIALOG_DEMO"
        @click="redirectToDemo"
      />
      <WsButton
        :href="`${WEBAPP_BASE_URL}/?upgradeNow`"
        :label="strings.upgrade"
        aid="ACCOUNT_SETTINGS_UPGRADE_DIALOG_UPGRADE"
        @click="redirectToUpgrade"
      />
    </WsDialogActions>
  </div>
</template>

<style lang="scss" scoped>
.upgrade-dialog {
  width: 400px;
}
</style>
