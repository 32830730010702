import { reactive } from 'vue';
import { currentUser } from '@mfl/platform-shell';
import { GetInfoResponse } from '@msl/account-settings-users-gateway-sdk';

export const initSettingsState = async (): Promise<void> => {
  const { userId, accountId } = currentUser;
  accountSettingsState.userId = userId;
  accountSettingsState.accountId = accountId;
};

export const accountSettingsState = reactive({
  userId: '',
  accountId: '',
  userEmail: undefined as GetInfoResponse['userEmail'],
  trustPolicyLevel: '1' as GetInfoResponse['trustPolicyLevel'],
  accountOwnerId: undefined as GetInfoResponse['accountOwnerId'],
  companyName: undefined as GetInfoResponse['companyName'],
  plans: [] as GetInfoResponse['plans'],
});
