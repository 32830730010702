<script setup lang="ts">
import { WsButton } from '@mfl/common-components';
import strings from './account-settings.strings';

function back() {
  window.history.back();
}
</script>

<template>
  <WsButton
    aid="ACCOUNT_SETTINGS_HEADER_BACK"
    :label="strings.headerBackButtonLabel"
    icon="fa-regular fa-chevron-left"
    variant="text"
    color="gray-500"
    type="button"
    @click="back"
  />
</template>
