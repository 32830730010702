export default {
  admin: 'Admin',
  adminFormAddHeader: 'Add User',
  adminFormChangeOwnerPromptHeader: 'Confirm ownership transfer',
  adminFormChangeOwnerPromptQuestion:
    'Are you sure you want to transfer the ownership of this account to {{name}}?',
  adminFormEditHeader: 'Edit User',
  adminFormErrorEmail: 'Please enter a valid email address.',
  adminFormErrorName: 'Please enter a valid name.',
  adminFormErrorOrganization: 'Please assign this user to an organization.',
  adminFormErrorRole: 'Please select a role for this user.',
  adminFormFailSave: 'Failed to save the user',
  adminFormInviteFail: 'Failed to send invite',
  adminFormInviteSent: 'Invite succesfully sent',
  adminFormSaved: 'User succesfully saved',
  allOrganizations: 'All organizations',
  cancel: 'Cancel',
  confirm: 'Confirm',
  designer: 'Designer',
  domain_manager: 'Organization manager', // the roles are loded from DB and it saved with "_" , please do not change!
  email: 'Email',
  hr: 'HR',
  it: 'IT',
  marketer: 'Marketer',
  name: 'Name',
  organizations: 'Organizations',
  owner: 'Owner',
  role: 'Role',
  save: 'Save',
  upgrade: 'Upgrade',
};
